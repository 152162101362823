import 'bootstrap/dist/css/bootstrap.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'video.js/dist/video-js.min.css';

import App from './App';
import './index.css';
import { unregister } from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
unregister();
