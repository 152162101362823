import * as React from 'react';

import './App.css';
import PlayerInfo from './models/player-info';
import HdRelayService from './services/hd-relay.service';
import VideoPlayer from './VideoPlayer';

interface State {
  players: PlayerInfo[];
}

class App extends React.Component<any, State> {
  private hdRelayService: HdRelayService;
  private readonly playerIds = [
    'f613f8c0-90b2-4442-8a0f-4a226b1456c8',
    'ddbc6aa7-c156-41b6-9db7-d6da7cb7173c',
    'a10515a2-e12a-4646-af7b-87c7366a718a',
    '81d14ae5-acac-4b8f-9896-64aee4ca6de8',
    'CID_YBHQ0000002A',
    'eac65ac1-1824-4e82-8205-16338bd9fc71',
  ];

  constructor(props: any) {
    super(props);
    this.hdRelayService = new HdRelayService();

    this.state = { players: [] };
  }

  public async componentDidMount() {
    const players = [];
    for (const playerId of this.playerIds) {
      const response = await this.hdRelayService.getPlayer(playerId);
      players.push(response);
    }
    this.setState({ players });
  }

  private getVideoPlayerList(players: PlayerInfo[]) {
    return players.map((player) => (
      <div key={player.camera.id} className="col-12 col-lg-6">
        <VideoPlayer
          sources={[
            {
              src: `https:${player.server.hls}/camera/${player.camera.id}/relay/playlist.m3u8`,
              type: 'application/x-mpegURL',
            },
          ]}
        />
      </div>
    ));
  }

  public render() {
    return (
      <div className="container-fluid">
        <div className="row no-gutters">{this.getVideoPlayerList(this.state.players)}</div>
      </div>
    );
  }
}

export default App;
