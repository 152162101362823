import * as React from 'react';
import videoJs, { VideoJsPlayerOptions } from 'video.js';

interface Props {
  sources: [
    {
      src: string;
      type: string;
    }
  ];
}

export default class VideoPlayer extends React.Component<Props> {
  public player: any;
  public videoNode: HTMLVideoElement | null;

  public componentDidMount() {
    // instantiate Video.js
    this.player = videoJs(this.videoNode, {
      ...this.props,
      autoplay: false,
      controls: true,
      fluid: true,
      liveui: true,
      nativeControlsForTouch: false,
      preload: 'none',
      muted: true,
    } as VideoJsPlayerOptions);
  }

  // destroy player on unmount
  public componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  public render() {
    return (
      <div>
        <div data-vjs-player={true}>
          <video playsInline ref={(node) => (this.videoNode = node)} className="video-js vjs-fluid" />
        </div>
      </div>
    );
  }
}
