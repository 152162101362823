import axios from 'axios';

import PlayerInfo from '../models/player-info';

export default class HdRelayService {
  public async getPlayer(id: string): Promise<PlayerInfo> {
    const url = `https://manage.hdrelay.com/player/${id}?cl=chrome=71.0.3578.98`;
    return (await axios.get<PlayerInfo>(url)).data;
  }
}
